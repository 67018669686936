import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
var ua = navigator.userAgent,
isWindowsPhone = /(?:Windows Phone)/.test(ua),
isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
isAndroid = /(?:Android)/.test(ua),
isFireFox = /(?:Firefox)/.test(ua),
isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
isPhone = /(?:iPhone)/.test(ua) && !isTablet,
isPc = !isPhone && !isAndroid && !isSymbian;
const routes = [
	{
		path: '/Home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "about" */'@/views/Home/Home.vue'),
		meta:{keepAlive:true},
		children : [
			// {
			// 	path: '/sanya',
			// 	name: 'sanya',
			// 	component: () => import(/* webpackChunkName: "about" */'@/views/sanya/sanya.vue'),
			// 	// meta:{keepAlive:true}
			// },
			{
				path: '/assembly',
				name: 'assembly',
				component: () => import(/* webpackChunkName: "about" */'@/views/assembly/assembly.vue'),
			},
			{
				path: '/previousTedx',
				name: 'previousTedx',
				component: () => import(/* webpackChunkName: "about" */'@/views/assembly/previousTedx.vue'),
			},
			{
				path: '/video',
				name: 'video',
				component: () => import(/* webpackChunkName: "about" */'@/views/video/video.vue'),
			},
			{
				path: '/join',
				name: 'join',
				component: () => import(/* webpackChunkName: "about" */'@/views/join/join.vue'),
			},
			{
				path: '/partner',
				name: 'partner',
				component: () => import(/* webpackChunkName: "about" */'@/views/join/partner.vue'),
			},
			{
				path: '/speaker',
				name: 'speaker',
				component: () => import(/* webpackChunkName: "about" */'@/views/join/speaker.vue'),
			},
			{
				path: '/volunteer',
				name: 'volunteer',
				component: () => import(/* webpackChunkName: "about" */'@/views/join/volunteer.vue'),
			},
			{
				path: '/about',
				name: 'about',
				component: () => import(/* webpackChunkName: "about" */'@/views/about/about.vue'),
			},
			{
				path: '/contact',
				name: 'contact',
				component: () => import(/* webpackChunkName: "about" */'@/views/contact/contact.vue'),
			},
			{
				path: '/audience',
				name: 'audience',
				component: () => import(/* webpackChunkName: "about" */'@/views/audience/audience.vue'),
			}
		]
	},
	{
		path: '/MHome',
		name: 'MHome',
		component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/Home.vue'),
		meta:{keepAlive:true},
		children : [
			// {
			// 	path: '/Msanya',
			// 	name: 'Msanya',
			// 	component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/sanya.vue'),
			// 	// meta:{keepAlive:true}
			// },
			{
				path: '/Massembly',
				name: 'Massembly',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/assembly.vue'),
			},
			{
				path: '/MpreviousTedx',
				name: 'MpreviousTedx',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/previousTedx.vue'),
			},
			{
				path: '/Mvideo',
				name: 'Mvideo',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/video.vue'),
			},
			{
				path: '/Mjoin',
				name: 'Mjoin',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/join.vue'),
			},
			{
				path: '/Mabout',
				name: 'Mabout',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/about.vue'),
			},
			{
				path: '/Mcontact',
				name: 'Mcontact',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/contact.vue'),
			},
			{
				path: '/Mspeaker',
				name: 'Mspeaker',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/speaker.vue'),
			},
			{
				path: '/Mpartner',
				name: 'Mpartner',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/partner.vue'),
			},
			{
				path: '/Mvolunteer',
				name: 'Mvolunteer',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/volunteer.vue'),
			},
			{
				path: '/Maudience',
				name: 'Maudience',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/audience.vue'),
			}
		]
	},

	{
        path: '/',
        redirect: '/assembly',
    },
	{
        path: '*',
        redirect: '/assembly',
    },
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass:'active',
    scrollBehavior: () => ({ y: 0 }),  //路由跳转后页面回到顶部
	routes
})

//全局路由守卫
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
	document.querySelector('#app').scrollTop = 0;
	if(isPc){
		next()
	}else{//移动端页面跳转
		if(to.name == 'assembly'){
			next({ path:'/Massembly' })
		}else if(to.name == 'video'){
			next({ path:'/Mvideo' })
		}else if(to.name == 'about'){
			next({ path:'/Mabout' })
		}else if(to.name == 'contact'){
			next({ path:'/Mcontact' })
		}else if(to.name == 'join'){
			next({ path:'/Mjoin' })
		}else if(to.name == 'speaker'){
			next({ path:'/Mspeaker' })
		}else if(to.name == 'partner'){
			next({ path:'/Mpartner' })
		}else if(to.name == 'volunteer'){
			next({ path:'/Mvolunteer' })
		}else if(to.name == 'audience'){
			next({ path:'/Maudience' })
		}else if(to.name == 'previousTedx'){
			next({ path:'/MpreviousTedx' })
		}else{
			next()
		}
	}
    
})
export default router

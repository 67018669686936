<template>
    <div id="app">
        <keep-alive v-if="isRouterAlive">
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>
<script>
import {config , get_sts_token} from './api/api'
export default {
    data(){
        return {
            isRouterAlive : true,
        }
    },
    mounted(){
        // document.onkeydown = function() {
        //     var e = window.event || arguments[0];
        //     if (e.keyCode == 123) {
        //         return false;
        //     }
        // }
        // 屏蔽鼠标右键
        document.oncontextmenu = function() {
            return false;
        }
        this.getStsTokenFn()
        config().then(res => {
            if(res.data.code == 10000){
                var obj = {}
                res.data.data.forEach(item => {
                    obj[item.key] = item
                })
                localStorage.setItem("config", JSON.stringify(obj));
            }
        })
    },
    methods : {
        getStsTokenFn(){
			get_sts_token().then((res) => {
				if (res.data.code === "10000") {
					localStorage.setItem("accessKeyId", res.data.data.AccessKeyId);
					localStorage.setItem("accessKeySecret", res.data.data.AccessKeySecret);
					localStorage.setItem("stsToken", res.data.data.SecurityToken);
					localStorage.setItem("region", res.data.data.regionId);
					var date = new Date(res.data.data.Expiration);
					var timestamp = date.getTime() - res.data.time * 1000;
					setTimeout(() => {
						this.getStsTokenFn()
					} , timestamp)
				}
			});
		}
    }
}
</script>

<style lang="less">

</style>

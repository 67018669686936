import request from './../request/request'
//视频获取
export function video (params) {
    return request.post('/video.html',params)
}
//广告获取
export function banner (params) {
    return request.post('/banner.html',params)
}
//合作伙伴查询
export function partner (params) {
    return request.post('/partner.html',params)
}
//年度大会
export function agm (params) {
    return request.post('/agm.html',params)
}
//问题查询
export function question (params) {
    return request.post('/question.html',params)
}
//讲者查询
export function talker (params) {
    return request.post('/talker.html',params)
}
//讲者申请
export function talker_apply (params) {
    return request.post('/talker_apply.html',params)
}
//志愿者申请
export function volunteer_apply (params) {
    return request.post('/volunteer_apply.html',params)
}
//观众申请
export function audience_apply (params) {
    return request.post('/audience_apply.html',params)
}
//联系我们提交
export function contact (params) {
    return request.post('/contact.html',params)
}
//短信验证码发送
export function sms (params) {
    return request.post('/sms.html',params)
}

//系统配置查询
export function config (params) {
    return request.post('/config.html',params)
}
//前台获取OSS临时授权token
export function get_sts_token (params) {
    return request.post('/get_sts_token.html',params)
}